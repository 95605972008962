import NowPlayingClientV2 from 'nowplaying-client-2';

interface NowPlayingConfiguration {
  server: string;
}

interface NowPlayingClientInterface {
  register_callback: (
    programId: string,
    type: string,
    callback: CallableFunction
  ) => NowPlayingSubscription;
}

export interface NowPlayingSubscription {
  unregister: () => void;
}

export default function NowPlayingClientFactory({
  server
}: NowPlayingConfiguration): NowPlayingClientInterface {
  return new NowPlayingClientV2({ server });
}
