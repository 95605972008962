import React, { FC } from 'react';
interface Props {
  title: string;
  subTitle?: string;
}

const AudioPlayerTrackInfo: FC<Props> = (props) => {
  return (
    <div className="textSection">
      <div className="player_title js-player-title">
        <div className="player_titleInner">
          <strong>
            <span>{props.title}</span>
          </strong>
        </div>
      </div>
      <div className="player_subhead">{props.subTitle}</div>
    </div>
  );
};

export default AudioPlayerTrackInfo;
