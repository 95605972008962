export const dataFooter = {
  columnA: {
    homeLink: {
      text: 'Minnesota Public Radio · The Current',
      url: '/'
    },
    logos: [
      {
        alt: 'Minnesota Public Radio',
        src: 'https://www.mprnews.org/mpr-logo-footer.svg',
        url: 'https://www.mpr.org/'
      },
      {
        alt: 'Supported by Minnesota Legacy Amendment Arts and Cultural Heitage Fund',
        src: 'https://kyigt1bcans3ofli94di0kch-wpengine.netdna-ssl.com/wp-content/themes/thecurrent/images/legacy.png',
        url: 'https://www.mpr.org/public/legacy-amendment-projects/'
      }
    ]
  },
  columnB: [
    {
      text: 'About',
      url: '/about'
    },
    {
      text: 'Become a Member',
      url: 'https://support.mpr.org/current-web',
      newWindow: true
    },
    {
      text: 'Volunteer',
      url: 'http://www.mpr.org/volunteer',
      newWindow: true
    },
    {
      text: 'Contact Us',
      url: 'https://www.mpr.org/contact',
      newWindow: true
    },
    {
      text: 'Shop',
      url: 'https://www.publicmediamarket.org/collections/the-current',
      newWindow: true
    }
  ],
  columnC: [
    {
      text: 'Terms of use',
      url: 'https://www.americanpublicmedia.org/terms/',
      newWindow: true
    },
    {
      text: 'Your privacy rights',
      url: 'https://www.americanpublicmedia.org/privacy/',
      newWindow: true
    },
    {
      text: 'Fundraising Credentials',
      url: 'http://www.mpr.org/support/fundraising-credentials',
      newWindow: true
    },
    {
      text: 'Station Outage Information',
      url: 'https://www.mpr.org/station-outages?the-current',
      newWindow: true
    }
  ],
  copyright: 'Minnesota Public Radio. All rights reserved.'
};

export default dataFooter;
