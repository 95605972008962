import { FC } from 'react';

interface Props {
  height?: string;
  width?: string;
  color?: string;
}

const IconMenu: FC<Props> = ({
  height = '14',
  width = '19',
  color = 'var(--color-text)'
}) => {
  return (
    <svg
      width={width}
      height={height}
      color={color}
      viewBox="0 0 19 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line y1="1.25" x2="19" y2="1.25" stroke="white" strokeWidth="1.5" />
      <line y1="7.25" x2="19" y2="7.25" stroke="white" strokeWidth="1.5" />
      <line y1="13.25" x2="19" y2="13.25" stroke="white" strokeWidth="1.5" />
    </svg>
  );
};

export default IconMenu;
