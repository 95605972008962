export const links = [
  {
    name: 'Listen',
    href: '/listen/programs',
    description: 'Streams, Programs, Podcasts',
    elementClass: 'IconMenuListen'
  },
  {
    name: 'Watch',
    href: '/watch',
    description: 'Sessions, Interviews, and More',
    elementClass: 'IconMenuWatch'
  },
  {
    name: 'The Gig List',
    href: '/events',
    description: 'Upcoming Events',
    elementClass: 'IconMenuGigList'
  },
  {
    name: 'Discover Music',
    href: '/discover-music',
    description: 'New Tracks, Music News, and Local Music',
    elementClass: 'IconMenuRead'
  },
  {
    name: 'Hosts',
    href: '/staff',
    description: 'Meet The Current’s Hosts',
    elementClass: 'IconMenuHost'
  },
  {
    name: 'Lists',
    href: '/lists',
    description: 'Best of Playlists',
    elementClass: 'IconMenuSchedule'
  },
  {
    name: 'Cross Currents Signups',
    href: '/newsletter',
    description: 'Stay Up to Date on Concert Presales, Music News, and More',
    elementClass: 'IconMenuNewsletter'
  },
  {
    name: 'Shop',
    href: 'https://www.publicmediamarket.org/collections/the-current',
    description: 'Get Exclusive Merch from The Current',
    elementClass: 'IconMenuShop'
  }
];

export default links;
