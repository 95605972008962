import { FC } from 'react';

interface Props {
  height?: string;
  width?: string;
  color?: string | undefined | null;
}

const IconCalendar: FC<Props> = ({ height = '22', width = '22', color }) => {
  let currentProgram;

  switch (color) {
    case 'the-current':
      currentProgram = 'var(--color-bkgd-lt)';
      break;
    case 'carbon-sound':
      currentProgram = 'var(--color-bkgd-lt)';
      break;
    case 'radio-heartland':
      currentProgram = '#89421B';
      break;
    default:
      currentProgram = 'var(--color-bkgd-lt)';
  }
  return (
    <svg
      width={width}
      height={height}
      color={currentProgram}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_638_187472)">
        <path
          d="M17.25 9.16659H0.75M12.6667 1.83325V5.49992M5.33333 1.83325V5.49992M5.15 20.1666H12.85C14.3901 20.1666 15.1602 20.1666 15.7485 19.8669C16.2659 19.6032 16.6866 19.1825 16.9503 18.6651C17.25 18.0768 17.25 17.3067 17.25 15.7666V8.06659C17.25 6.52644 17.25 5.75637 16.9503 5.16811C16.6866 4.65067 16.2659 4.22997 15.7485 3.96632C15.1602 3.66659 14.3901 3.66659 12.85 3.66659H5.15C3.60986 3.66659 2.83978 3.66659 2.25153 3.96632C1.73408 4.22997 1.31338 4.65067 1.04973 5.16811C0.75 5.75637 0.75 6.52644 0.75 8.06659V15.7666C0.75 17.3067 0.75 18.0768 1.04973 18.6651C1.31338 19.1825 1.73408 19.6032 2.25153 19.8669C2.83978 20.1666 3.60986 20.1666 5.15 20.1666Z"
          stroke={currentProgram}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_638_187472">
          <rect width="22" height="22" fill={currentProgram} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconCalendar;
