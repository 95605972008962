import { FC } from 'react';

interface Props {
  height?: string;
  width?: string;
}

const IconMenuHost: FC<Props> = ({ height = '18', width = '18' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.6045 16.2151C12.9445 16.4101 12.1645 16.5001 11.2495 16.5001H6.74953C5.83453 16.5001 5.05453 16.4101 4.39453 16.2151C4.55953 14.2651 6.56203 12.7275 8.99953 12.7275C11.437 12.7275 13.4395 14.2651 13.6045 16.2151Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.25 1.5H6.75C3 1.5 1.5 3 1.5 6.75V11.25C1.5 14.085 2.355 15.6375 4.395 16.215C4.56 14.265 6.5625 12.7275 9 12.7275C11.4375 12.7275 13.44 14.265 13.605 16.215C15.645 15.6375 16.5 14.085 16.5 11.25V6.75C16.5 3 15 1.5 11.25 1.5ZM9 10.6275C7.515 10.6275 6.315 9.42001 6.315 7.93501C6.315 6.45001 7.515 5.25 9 5.25C10.485 5.25 11.685 6.45001 11.685 7.93501C11.685 9.42001 10.485 10.6275 9 10.6275Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6854 7.93501C11.6854 9.42001 10.4854 10.6275 9.00043 10.6275C7.51543 10.6275 6.31543 9.42001 6.31543 7.93501C6.31543 6.45001 7.51543 5.25 9.00043 5.25C10.4854 5.25 11.6854 6.45001 11.6854 7.93501Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconMenuHost;
