export const socialIcons = [
  {
    name: 'facebook',
    url: 'https://www.facebook.com/TheCurrent'
  },
  {
    name: 'youtube',
    url: 'https://www.youtube.com/user/893TheCurrent'
  },
  {
    name: 'instagram',
    url: 'https://www.instagram.com/thecurrent'
  }
];

export default socialIcons;
