import { FC } from 'react';

interface Props {
  height?: string;
  width?: string;
  color?: string | undefined | null;
}

const IconMusicNote: FC<Props> = ({ height = '23', width = '22', color }) => {
  let currentProgram;

  switch (color) {
    case 'the-current':
      currentProgram = 'var(--color-bkgd-lt)';
      break;
    case 'carbon-sound':
      currentProgram = 'var(--color-bkgd-lt)';
      break;
    case 'radio-heartland':
      currentProgram = '#89421B';
      break;
    default:
      currentProgram = 'var(--color-bkgd-lt)';
  }

  return (
    <svg
      width={width}
      height={height}
      color={currentProgram}
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.25 17.2894V6.61522C8.25 6.17381 8.25 5.95311 8.3303 5.7741C8.40109 5.61631 8.51507 5.48176 8.65908 5.386C8.82244 5.27736 9.04015 5.24108 9.47555 5.16851L17.5422 3.82407C18.1298 3.72614 18.4235 3.67718 18.6525 3.76222C18.8534 3.83684 19.0218 3.97951 19.1285 4.16543C19.25 4.37729 19.25 4.67512 19.25 5.27078V15.4561M8.25 17.2894C8.25 18.8082 7.01878 20.0394 5.5 20.0394C3.98122 20.0394 2.75 18.8082 2.75 17.2894C2.75 15.7707 3.98122 14.5394 5.5 14.5394C7.01878 14.5394 8.25 15.7707 8.25 17.2894ZM19.25 15.4561C19.25 16.9749 18.0188 18.2061 16.5 18.2061C14.9812 18.2061 13.75 16.9749 13.75 15.4561C13.75 13.9373 14.9812 12.7061 16.5 12.7061C18.0188 12.7061 19.25 13.9373 19.25 15.4561Z"
        stroke={currentProgram}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconMusicNote;
