import { FC } from 'react';

interface Props {
  height?: string;
  width?: string;
}

const IconMenuSchedule: FC<Props> = ({ height = '18', width = '18' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 6.75V11.25C16.5 11.415 16.5 11.58 16.485 11.7375C15.87 10.98 14.9325 10.5 13.875 10.5C13.08 10.5 12.345 10.7775 11.7675 11.2425C10.9875 11.8575 10.5 12.81 10.5 13.875C10.5 14.9325 10.98 15.87 11.7375 16.485C11.58 16.5 11.415 16.5 11.25 16.5H6.75C3 16.5 1.5 15 1.5 11.25V6.75C1.5 3 3 1.5 6.75 1.5H11.25C15 1.5 16.5 3 16.5 6.75Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.88965 5.33252H16.1097"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.38965 1.58252V5.22751"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6094 1.58252V4.89005"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.25 13.875C17.25 14.8875 16.8 15.7875 16.1025 16.41C15.5025 16.9275 14.73 17.25 13.875 17.25C13.065 17.25 12.315 16.965 11.7375 16.485C10.98 15.87 10.5 14.9325 10.5 13.875C10.5 12.81 10.9875 11.8575 11.7675 11.2425C12.345 10.7775 13.08 10.5 13.875 10.5C14.9325 10.5 15.87 10.98 16.485 11.7375C16.965 12.315 17.25 13.065 17.25 13.875Z"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0852 12.8174V14.0849L13.0352 14.7149"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconMenuSchedule;
