import React, { FC } from 'react';

interface Props {
  isInfinite: boolean | undefined;
}

const AudioPlayerProgressBar: FC<Props> = ({ isInfinite }) => {
  return (
    <div className={`progressBarContainer ${isInfinite && 'invisible'}`}>
      <div className="player_time player_time-current js-player-currentTime">
        0:00
      </div>
      <div className="progressBarControls js-player-timeline">
        <div className="progressBar">
          <div className="timelineBg"></div>
          <div className="currentProgress player_timeline_progress js-player-progress"></div>
          <div className="player_timeline_buffered js-player-buffered">
            <div></div>
          </div>
          <div className="player_timeline_loading"></div>
        </div>
      </div>
      <div className="player_time player_time-right player_time-duration js-player-duration">
        0:00
      </div>
    </div>
  );
};

export default AudioPlayerProgressBar;
