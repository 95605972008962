'use client';
import React, { FC, useState, useEffect } from 'react';
import Link from 'next/link';
import LinkNewWindow from 'utils/LinkNewWindow';
import dataFooter from '__data__/Footer/dataFooter';
import socialIcons from './socialIcons';

import { IoLogoFacebook } from '@react-icons/all-files/io/IoLogoFacebook';
import { IoLogoYoutube } from '@react-icons/all-files/io/IoLogoYoutube';
import { IoLogoInstagram } from '@react-icons/all-files/io/IoLogoInstagram';
import { IoLogoTumblr } from '@react-icons/all-files/io/IoLogoTumblr';

interface SocialMediaIcon {
  name: string;
  url: string;
}

const Footer: FC = () => {
  const data = {
    socialMedia: {
      icons: [
        { name: 'facebook', logo: <IoLogoFacebook /> },
        { name: 'instagram', logo: <IoLogoInstagram /> },
        { name: 'tumblr', logo: <IoLogoTumblr /> },
        { name: 'youtube', logo: <IoLogoYoutube /> }
      ],
      getIcon(socialIcon: SocialMediaIcon): JSX.Element | string {
        const icon = this.icons.find((icon) => icon.name === socialIcon.name);
        return icon?.logo || '';
      }
    },
    year: new Date().getFullYear()
  };
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  });

  return mounted ? (
    <footer>
      <div className="wrapper">
        <div className="footer-logo">
          <a
            target="_blank"
            href="https://www.mpr.org"
            rel="noreferrer"
            aria-label="MPR - link opens in a new tab"
          >
            <img
              src="/images/mpr-logo-white.svg"
              alt="MPR Logo"
              loading="lazy"
            />
          </a>
        </div>
        <div className="footer-station-id type-hefty">
          <Link href={dataFooter.columnA.homeLink.url} prefetch={false}>
            {dataFooter.columnA.homeLink.text}
          </Link>
        </div>
        <ul className="footer-stationinfo-a">
          {dataFooter.columnB.map((link) => {
            return (
              <li key={link.url}>
                {link.newWindow ? (
                  <LinkNewWindow
                    href={link.url}
                    title={link.text}
                    inner={link.text}
                  />
                ) : (
                  <Link href={link.url} prefetch={false}>
                    {link.text}
                  </Link>
                )}
              </li>
            );
          })}
        </ul>
        <ul className="footer-stationinfo-b">
          {dataFooter.columnC.map((link) => {
            return (
              <li key={link.url}>
                {link.newWindow ? (
                  <LinkNewWindow
                    href={link.url}
                    title={link.text}
                    inner={link.text}
                  />
                ) : (
                  <Link href={link.url} prefetch={false}>
                    {link.text}
                  </Link>
                )}
              </li>
            );
          })}
        </ul>
        <div className="footer-applinks">
          <h2 className="type-strong">Get the app</h2>
          <div>
            <Link
              href="https://apps.apple.com/us/app/the-current-music/id1334353737"
              prefetch={false}
              passHref
              target="_blank"
              rel="noreferrer"
              aria-label="The Current App Store Application - link opens in a new tab"
            >
              <img
                src="/images/Download On The App Store (white outline version).svg"
                alt="Download On The App Store"
                loading="lazy"
              />
            </Link>
            <Link
              href="https://play.google.com/store/apps/details?id=org.americanpublicmedia.thecurrent&hl=en_US&gl=US"
              prefetch={false}
              passHref
              target="_blank"
              rel="noreferrer"
              aria-label="The Current Google Play Store Application - link opens in a new tab"
            >
              <img
                src="/images/Get It On Google Play (white outline version).svg"
                alt="Get It On Google Play"
                loading="lazy"
              />
            </Link>
          </div>
        </div>
        <ul className="footer-social">
          <li>
            {socialIcons.map((socialIcon) => {
              return (
                <Link
                  className="footer-social-icon type-hefty"
                  href={socialIcon.url}
                  prefetch={false}
                  target="_blank"
                  rel="noreferrer"
                  aria-label={`${socialIcon.name} - link opens in a new tab`}
                  key={socialIcon.url}
                >
                  {data.socialMedia.getIcon(socialIcon)}
                </Link>
              );
            })}
          </li>
        </ul>
        <div className="footer-copyright type-caption">
          © {data.year} {dataFooter.copyright}
        </div>
      </div>
    </footer>
  ) : null;
};

export default Footer;
