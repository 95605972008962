'use client';
import React, { FC, useState, useEffect } from 'react';
import Link from 'next/link';
import IconMenu from 'components/Icon/IconMenu';
import IconSearch from 'components/Icon/IconSearch';
import IconMenuRead from 'components/Icon/IconMenuRead';
import IconPerson from 'components/Icon/IconPerson';
import IconMenuListen from 'components/Icon/IconMenuListen';
import IconChevronCondensed from 'components/Icon/IconChevronCondensed';
import IconMenuHost from 'components/Icon/IconMenuHost';
import IconMenuSchedule from 'components/Icon/IconMenuSchedule';
import IconMenuContact from 'components/Icon/IconMenuContact';
import IconMenuWatch from 'components/Icon/IconMenuWatch';
import IconMenuGigList from 'components/Icon/IconMenuGigList';
import IconMenuShop from 'components/Icon/IconMenuShop';
import IconMenuNewsletter from 'components/Icon/IconMenuNewsletter';
import megaMenuLinks from './NavConfig';
import socialIcons from './socialIcons';

import { IoLogoFacebook } from '@react-icons/all-files/io/IoLogoFacebook';
import { IoLogoYoutube } from '@react-icons/all-files/io/IoLogoYoutube';
import { IoLogoInstagram } from '@react-icons/all-files/io/IoLogoInstagram';

interface SocialMediaIcon {
  name: string;
  url: string;
}

const Header: FC = () => {
  const [dropdownMenuOpen, setDropdownMenuOpen] = useState(0);
  const [mounted, setMounted] = useState(false);
  //dropDownMenu values explanation: 0 = all closed, 1 = Navigate mega menu, 2 = Ways to Give menu

  const Icons = (elementClass: string) => {
    switch (elementClass) {
      case 'IconMenuListen':
        return <IconMenuListen />;
      case 'IconMenuRead':
        return <IconMenuRead />;
      case 'IconMenuHost':
        return <IconMenuHost />;
      case 'IconMenuSchedule':
        return <IconMenuSchedule />;
      case 'IconMenuContact':
        return <IconMenuContact />;
      case 'IconMenuWatch':
        return <IconMenuWatch />;
      case 'IconMenuGigList':
        return <IconMenuGigList />;
      case 'IconMenuShop':
        return <IconMenuShop />;
      case 'IconMenuNewsletter':
        return <IconMenuNewsletter />;
      default:
        return '';
    }
  };

  const socialMediaIcons = [
    { name: 'facebook', logo: <IoLogoFacebook /> },
    { name: 'instagram', logo: <IoLogoInstagram /> },
    { name: 'youtube', logo: <IoLogoYoutube /> }
  ];

  function getIcon(socialIcon: SocialMediaIcon): JSX.Element | string {
    const icon = socialMediaIcons.find((icon) => icon.name === socialIcon.name);
    return icon?.logo || '';
  }

  useEffect(() => {
    setMounted(true);
  });

  return (
    <header className="main-header">
      <div className="header-contents container">
        <div className="header-logo-and-text">
          <Link
            href="/"
            passHref
            prefetch={false}
            className="logo-link"
            onClick={() => setDropdownMenuOpen(0)}
          >
            <h1 className="logo">
              <img src="/images/the-current-logo.svg" alt="The Current" />
            </h1>
          </Link>
          <div className="header-text">
            <div>
              <span className="type-large">Great Music Lives Here</span>
              <span className="type-hefty"> &reg;</span>
            </div>
            <div className="subtitle type-article">
              Listener-Supported Music
            </div>
          </div>
          <Link
            href="https://support.mpr.org/current-web"
            passHref
            prefetch={false}
            className="btn header-donate-btn"
            onClick={() => setDropdownMenuOpen(0)}
            target="_blank"
          >
            Donate Now
          </Link>
        </div>
        <nav className="header-nav">
          <ul className="nav-list type-strong">
            <li>
              <button
                onClick={() =>
                  setDropdownMenuOpen(dropdownMenuOpen === 1 ? 0 : 1)
                }
                className="main-nav-link"
              >
                <IconMenu />
                Navigate
              </button>
              <nav
                className={`dropdown-menu mega-menu container ${
                  dropdownMenuOpen === 1 ? '' : 'dropdown-menu-hidden'
                }`}
                aria-hidden={dropdownMenuOpen === 1 ? false : true}
              >
                <div className="menu-items">
                  {mounted && (
                    <ul className="mega-menu-main-list">
                      {megaMenuLinks.map(
                        ({ name, href, description, elementClass }) => (
                          <li key={href}>
                            <Link
                              href={href}
                              className="menu-link"
                              onClick={() => setDropdownMenuOpen(0)}
                              prefetch={false}
                              passHref
                            >
                              <div className="menu-link-name">
                                {Icons(elementClass)}
                                {name}
                              </div>
                              <div className="menu-link-description">
                                {description}
                              </div>
                            </Link>
                          </li>
                        )
                      )}
                      <li>
                        <div className="menu-social-container">
                          <h2 className="social-header">Follow The Current</h2>
                          <ul>
                            <li>
                              {socialIcons.map((socialIcon) => {
                                return (
                                  <a
                                    className="header-social-icon type-hefty"
                                    href={socialIcon.url}
                                    target="_blank"
                                    rel="noreferrer"
                                    aria-label={`${socialIcon.name} - link opens in a new tab`}
                                    key={socialIcon.url}
                                  >
                                    {getIcon(socialIcon)}
                                  </a>
                                );
                              })}
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  )}
                </div>
                <div className="menu-link-donate-container">
                  <div className="menu-link-donate-logo">
                    <img src="/images/the-current-logo.svg" alt="The Current" />
                  </div>
                  <div className="menu-link-donate-header type-hefty">
                    Great Music
                  </div>
                  <div className="menu-link-donate-header type-hefty">
                    Lives Here.
                  </div>
                  <div className="menu-link-donate-sub-header type-copy">
                    Listener-Supported Music
                  </div>
                  <Link
                    href="https://support.mpr.org/current-web"
                    prefetch={false}
                    passHref
                    className="btn"
                    onClick={() => setDropdownMenuOpen(0)}
                    target="_blank"
                  >
                    Donate Now
                  </Link>
                </div>
              </nav>
            </li>
            <li>
              <Link
                href="/for-you"
                prefetch={false}
                passHref
                className="main-nav-link"
                onClick={() => setDropdownMenuOpen(0)}
              >
                <IconPerson />
                For You
              </Link>
            </li>
            <li>
              <button
                onClick={() =>
                  setDropdownMenuOpen(dropdownMenuOpen === 2 ? 0 : 2)
                }
                className="main-nav-link"
              >
                <IconChevronCondensed width="14" height="8" />
                Ways to Give
              </button>
              <nav
                className={`dropdown-menu ${
                  dropdownMenuOpen === 2 ? '' : 'dropdown-menu-hidden'
                }`}
                aria-hidden={dropdownMenuOpen === 2 ? false : true}
              >
                {mounted && (
                  <ul>
                    <li>
                      <a
                        href="https://support.mpr.org/current-web"
                        className="menu-link"
                      >
                        <div className="menu-link-name">
                          Start a Monthly Gift
                        </div>
                      </a>
                    </li>
                    <li>
                      <Link
                        href="/waystogive"
                        className="menu-link"
                        prefetch={false}
                        passHref
                        onClick={() => setDropdownMenuOpen(0)}
                      >
                        <div className="menu-link-name">More Ways to Give</div>
                      </Link>
                    </li>
                    <li>
                      <Link
                        href="/vehicle"
                        className="menu-link"
                        prefetch={false}
                        passHref
                        onClick={() => setDropdownMenuOpen(0)}
                      >
                        <div className="menu-link-name">Donate a Vehicle</div>
                      </Link>
                    </li>
                    <li>
                      <Link
                        href="/waystogive#h2_planned_giving"
                        className="menu-link"
                        prefetch={false}
                        passHref
                        onClick={() => setDropdownMenuOpen(0)}
                      >
                        <div className="menu-link-name">Planned Giving</div>
                      </Link>
                    </li>
                  </ul>
                )}
              </nav>
            </li>
            <li>
              <Link
                href="/search"
                prefetch={false}
                passHref
                className="main-nav-link"
                onClick={() => setDropdownMenuOpen(0)}
              >
                <span aria-label="Search">
                  <IconSearch />
                </span>
              </Link>
            </li>
          </ul>
          <Link
            href="https://support.mpr.org/current-web"
            prefetch={false}
            passHref
            className="btn header-donate-btn-desktop"
            onClick={() => setDropdownMenuOpen(0)}
            target="_blank"
          >
            Donate Now
          </Link>
        </nav>
      </div>
    </header>
  );
};

export default Header;
