import { FC } from 'react';

interface Props {
  height?: string;
  width?: string;
}

const IconMenuRead: FC<Props> = ({ height = '18', width = '18' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 12.5548V3.50228C16.5 2.60228 15.765 1.93478 14.8725 2.00978H14.8275C13.2525 2.14478 10.86 2.94728 9.525 3.78728L9.3975 3.86978C9.18 4.00478 8.82 4.00478 8.6025 3.86978L8.415 3.75728C7.08 2.92478 4.695 2.12978 3.12 2.00228C2.2275 1.92728 1.5 2.60228 1.5 3.49478V12.5548C1.5 13.2748 2.085 13.9498 2.805 14.0398L3.0225 14.0698C4.65 14.2873 7.1625 15.1123 8.6025 15.8998L8.6325 15.9148C8.835 16.0273 9.1575 16.0273 9.3525 15.9148C10.7925 15.1198 13.3125 14.2873 14.9475 14.0698L15.195 14.0398C15.915 13.9498 16.5 13.2748 16.5 12.5548Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 4.11768V15.3677"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.8125 6.36768H4.125"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.375 8.61768H4.125"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconMenuRead;
