import { FC } from 'react';

interface Props {
  height?: string;
  width?: string;
}

const IconMenuListen: FC<Props> = ({ height = '18', width = '18' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 11.25V6.75C16.5 3 15 1.5 11.25 1.5H6.75C3 1.5 1.5 3 1.5 6.75V11.25C1.5 15 3 16.5 6.75 16.5H11.25C15 16.5 16.5 15 16.5 11.25Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.215 12.9749C8.09313 12.9749 8.80499 12.2631 8.80499 11.3849C8.80499 10.5068 8.09313 9.79492 7.215 9.79492C6.33686 9.79492 5.625 10.5068 5.625 11.3849C5.625 12.2631 6.33686 12.9749 7.215 12.9749Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.80469 11.3851V5.82764"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.8472 5.07757L11.6022 5.66255C12.0297 5.80505 12.3747 6.28504 12.3747 6.73504V7.20004C12.3747 7.80754 11.9022 8.14504 11.3322 7.95004L9.57721 7.36506C9.14971 7.22256 8.80469 6.74257 8.80469 6.29257V5.82757C8.80469 5.22757 9.2697 4.88257 9.8472 5.07757Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconMenuListen;
