'use client';
import React, { FC, useContext } from 'react';
import { AudioState } from 'context/AudioContext';
import { AudioContext } from 'context/AudioContext';
import AudioPlayer from './AudioPlayer';

const AudioWrapper: FC = () => {
  const context = useContext<AudioState>(AudioContext);

  return <AudioPlayer {...context} />;
};

export default AudioWrapper;
