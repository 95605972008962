import { FC } from 'react';

interface Props {
  height?: string;
  width?: string;
  color?: string;
}

const IconPerson: FC<Props> = ({
  height = '20',
  width = '20',
  color = 'var(--color-text)'
}) => {
  return (
    <svg
      width={width}
      height={height}
      color={color}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="user-circle" clipPath="url(#clip0_538_180649)">
        <path
          id="Icon"
          d="M4.43008 16.1985C4.93702 15.0042 6.12061 14.1665 7.49984 14.1665H12.4998C13.8791 14.1665 15.0627 15.0042 15.5696 16.1985M13.3332 7.9165C13.3332 9.75745 11.8408 11.2498 9.99984 11.2498C8.15889 11.2498 6.6665 9.75745 6.6665 7.9165C6.6665 6.07555 8.15889 4.58317 9.99984 4.58317C11.8408 4.58317 13.3332 6.07555 13.3332 7.9165ZM18.3332 9.99984C18.3332 14.6022 14.6022 18.3332 9.99984 18.3332C5.39746 18.3332 1.6665 14.6022 1.6665 9.99984C1.6665 5.39746 5.39746 1.6665 9.99984 1.6665C14.6022 1.6665 18.3332 5.39746 18.3332 9.99984Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_538_180649">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconPerson;
