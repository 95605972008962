import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/thecurrent/components/AudioPlayer/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/thecurrent/components/Session/SessionTracker.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AudioProvider"] */ "/opt/thecurrent/context/AudioContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NextAuthProvider"] */ "/opt/thecurrent/context/NextAuthProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SiteContextProvider"] */ "/opt/thecurrent/context/SiteContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/thecurrent/layout/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/thecurrent/layout/Header.tsx");
;
import(/* webpackMode: "eager" */ "/opt/thecurrent/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/opt/thecurrent/styles/styles.css");
