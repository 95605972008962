import { FC } from 'react';

interface Props {
  height?: string;
  width?: string;
  color?: string;
}

const IconSearch: FC<Props> = ({
  height = '18',
  width = '18',
  color = 'var(--color-text)'
}) => {
  return (
    <svg
      width={width}
      height={height}
      color={color}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 17L11.6668 11.6667M13.4444 7.22222C13.4444 10.6587 10.6587 13.4444 7.22222 13.4444C3.78578 13.4444 1 10.6587 1 7.22222C1 3.78578 3.78578 1 7.22222 1C10.6587 1 13.4444 3.78578 13.4444 7.22222Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconSearch;
