import { FC } from 'react';

interface Props {
  height?: string;
  width?: string;
}

const IconChevron: FC<Props> = ({ height = '12', width = '7' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 12 7"
      enableBackground="new 0 0 12 7"
    >
      <path
        fill="none"
        stroke="#FFFFFF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M1,1l5,5l5-5"
      />
    </svg>
  );
};

export default IconChevron;
