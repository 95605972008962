import React, { FC, useState } from 'react';

const AudioPlayerVolume: FC = () => {
  const [isMute, setIsMute] = useState<boolean>(false);

  const data = {
    styleRemoved: 'invisible',
    setIsMute(): void {
      setIsMute(!isMute);
    }
  };

  return (
    <div className="volumeBarContainer player_controlsSecondary">
      <button
        onClick={data.setIsMute}
        className="iconButtonVolume js-player-mute"
        type="button"
      >
        <span
          className={`player_volume player_btn-medium ${
            isMute && data.styleRemoved
          }`}
        >
          <img src="/images/volume-min.svg" alt="Mute Sound" />
        </span>
        <span
          className={`player_mute player_btn-medium ${
            !isMute && data.styleRemoved
          }`}
        >
          <img src="/images/volume-min-crossed-out.svg" alt="Unmute Sound" />
        </span>
      </button>

      <div
        className="volumeBarControls js-player-volume"
        data-volume-direction="h"
      >
        <div className="volumeBar">
          <div className="currentProgress js-player-volume-current"></div>
        </div>
      </div>

      <button className="iconButtonVolume js-player-mute" type="button">
        <img src="/images/volume-max.svg" alt="Set Volume to Max" />
      </button>
    </div>
  );
};

export default AudioPlayerVolume;
