'use client';
import { FC, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useSession } from 'next-auth/react';

declare const window: Window & { dataLayer: Record<string, unknown>[] };

const tagManagerArgs = {
  gtmId: 'GTM-WW9LZ7H'
};

const SessionTracker: FC = () => {
  const session = useSession();
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    if (session?.data?.user) {
      window.dataLayer.push({
        event: 'sessionView',
        user_id: session?.data?.user?.id
      });
    }
    TagManager.initialize(tagManagerArgs);
  }, [session.data]);

  return null;
};

export default SessionTracker;
