'use client';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import NowPlayingClientFactory from 'utils/NowPlayingFactory';
import streamsData from 'config/streams.json';
import { PlaylistData, ScheduleData, Song } from 'types/SongScheduleData';
import { cleanAudioSource } from 'utils';

const STREAM_IDS = streamsData.map((stream) => stream.programId);
const SCHEDULED_STREAM_IDS = ['classical-mpr', 'classical-24'];
export interface PlayerInterface {
  init: () => void;
  unloadAudio: () => void;
  handlePlay: () => void;
  isPlaying: boolean;
}

export interface AudioState {
  source: string;
  programId?: string | null;
  title: string;
  subtitle: string;
  isAudioPlaying: boolean;
  handleAudioButtonClick?: (
    source: string | null | undefined,
    title: string,
    subtitle: string,
    programId?: string | undefined | null,
    mobileImage?: string | undefined,
    id?: string,
    resourceType?: string,
    slug?: string
  ) => void;
  loadPlayer?: () => void;
  playerInstance?: PlayerInterface;
  playerElementRef?: React.RefObject<HTMLDivElement>;
  audioElementRef?: React.RefObject<HTMLAudioElement>;
  duration?: number | undefined;
  usesPlaylist?: boolean;
  id?: string;
  resourceType?: string;
  slug?: string;
  setProgramId?: (programId: string) => void;
  song?: Song;
}

const defaultAudioData: AudioState = {
  title: streamsData[0].title,
  source: streamsData[0].encodings[0].httpFilePath,
  programId: streamsData[0].programId,
  subtitle: '',
  isAudioPlaying: false,
  duration: Infinity,
  id: '',
  slug: '',
  resourceType: ''
};

type Props = {
  children: React.ReactNode;
};

export const AudioContext = React.createContext<AudioState>(defaultAudioData);

export const AudioProvider: FunctionComponent<Props> = ({ children }) => {
  const playerElementRef = useRef<HTMLDivElement>(null);
  const audioElementRef = useRef<HTMLAudioElement>(null);
  const [audioState, setAudioState] = useState({
    ...defaultAudioData,
    audioElementRef,
    playerElementRef
  });

  useEffect(() => {
    if (
      audioState.programId &&
      SCHEDULED_STREAM_IDS.includes(audioState.programId)
    ) {
      const nowplayingClient = NowPlayingClientFactory({
        server:
          process.env.NEXT_PUBLIC_NOWPLAYING_SERVER ||
          'wss://nowplayingv2.publicradio.org'
      });
      const scheduleRegistration = nowplayingClient.register_callback(
        audioState.programId,
        'schedule',
        function (data: ScheduleData) {
          const usesPlaylist = data?.schedule[0]?.shows[0]?.uses_playlist;
          setAudioState((prevState) => {
            return { ...prevState, usesPlaylist };
          });
        }
      );

      return () => {
        scheduleRegistration.unregister();
      };
    } else {
      setAudioState((prevState) => {
        return { ...prevState, usesPlaylist: undefined };
      });
    }
  }, [audioState.programId]);

  useEffect(() => {
    if (
      audioState.programId &&
      audioState.isAudioPlaying &&
      STREAM_IDS.includes(audioState.programId)
    ) {
      if (audioState.usesPlaylist === false) {
        setAudioState((prevState) => {
          return {
            ...prevState,
            subtitle: ''
          };
        });
      } else {
        const nowplayingClient = NowPlayingClientFactory({
          server:
            process.env.NEXT_PUBLIC_NOWPLAYING_SERVER ||
            'wss://nowplayingv2.publicradio.org'
        });
        const playlistRegistration = nowplayingClient.register_callback(
          audioState.programId,
          'playlist',
          function (data: PlaylistData) {
            const song = data.plays[0].song;
            setAudioState((prevState) => {
              return {
                ...prevState,
                subtitle: `${song.title} by ${song.artist}`,
                id: song.song_id?.toString(),
                song: song
              };
            });
          }
        );

        return () => {
          playlistRegistration.unregister();
        };
      }
    }
  }, [
    audioState.programId,
    audioState.isAudioPlaying,
    audioState.usesPlaylist
  ]);

  const loadPlayer = async () => {
    // It appears we have to load this in the front end
    const { Player } = await require('apm-html5-player');
    const playerInstance = await new Player(playerElementRef.current).init();
    setAudioState((prevState) => {
      return { ...prevState, playerInstance };
    });

    const playListener = () => {
      setAudioState((prevState) => {
        return { ...prevState, isAudioPlaying: true };
      });
    };

    const pauseListener = () => {
      setAudioState((prevState) => {
        return { ...prevState, isAudioPlaying: false };
      });
    };

    const durationListener = () => {
      setAudioState((prevState) => {
        return { ...prevState, duration: audioElementRef?.current?.duration };
      });
    };

    audioState.audioElementRef.current?.addEventListener('play', playListener);
    audioState.audioElementRef.current?.addEventListener(
      'pause',
      pauseListener
    );
    audioState.audioElementRef?.current?.addEventListener(
      'durationchange',
      durationListener
    );

    audioState.audioElementRef?.current?.addEventListener(
      'emptied',
      pauseListener
    );
  };

  const handleAudioButtonClick = async (
    source: string | null | undefined,
    title: string,
    subtitle: string,
    programId?: string | undefined | null,
    mobileImage?: string | undefined,
    id?: string,
    resourceType?: string
  ) => {
    if (!audioState.playerInstance || !source) return;

    source = cleanAudioSource(source);
    if (!source) return;

    if (audioState.isAudioPlaying && audioState.source === source) {
      pauseAudio();
    } else if (audioState.source !== source) {
      await audioState.playerInstance.unloadAudio();
      playAudio(
        source,
        title,
        subtitle,
        programId,
        mobileImage,
        id,
        resourceType
      );
    } else {
      playAudio(
        source,
        title,
        subtitle,
        programId,
        mobileImage,
        id,
        resourceType
      );
    }
  };

  const playAudio = async (
    source: string,
    title: string,
    subtitle: string,
    programId?: string | undefined | null,
    mobileImage?: string | undefined,
    id?: string,
    resourceType?: string
  ) => {
    if (!audioState.playerInstance) return;

    await setAudioState((prevState) => {
      return {
        ...prevState,
        source,
        title,
        subtitle,
        programId,
        isAudioPlaying: true,
        mobileImage,
        id,
        resourceType
      };
    });
    audioState.playerInstance.handlePlay();
  };

  const pauseAudio = () => {
    if (!audioState.playerInstance) return;

    setAudioState((prevState) => {
      return { ...prevState, isAudioPlaying: false };
    });
    audioState.playerInstance.handlePlay();
  };

  function setProgramId(programId: string) {
    setAudioState((prevState) => {
      return { ...prevState, programId };
    });
  }

  return (
    <AudioContext.Provider
      value={{
        ...audioState,
        loadPlayer,
        handleAudioButtonClick,
        setProgramId
      }}
    >
      {children}
    </AudioContext.Provider>
  );
};
