import React, { FC } from 'react';

interface Props {
  href: string;
  title: string;
  inner: string;
}
const LinkNewWindow: FC<Props> = ({ href, title, inner }) => {
  return (
    <>
      <a
        href={href}
        title={title}
        target="_blank"
        rel="noreferrer"
        aria-label={`${title} - link opens in a new tab`}
      >
        {inner}
      </a>
    </>
  );
};

export default LinkNewWindow;
