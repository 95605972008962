import React, { FC } from 'react';

interface Props {
  forward?: number;
  replay?: number;
  isPlaying: boolean;
  isInfinite: boolean;
}

const AudioPlayerPlayControls: FC<Props> = (props) => {
  const style = {
    hidden: 'hidden',
    removed: 'invisible'
  };

  return (
    <div className="playerControls">
      <button
        aria-label="Play"
        className="playPauseButton js-player-play"
        type="button"
      >
        <span className={`player-play ${props.isPlaying && style.removed}`}>
          <img src="/images/icon-player-play.svg" alt="Play" />
          <span className="invisible">Play</span>
        </span>
        <span className={`player-pause ${!props.isPlaying && style.removed}`}>
          <img src="/images/icon-player-pause.svg" />
          <span className="invisible">Pause</span>
        </span>
      </button>
    </div>
  );
};

export default AudioPlayerPlayControls;
