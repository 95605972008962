'use client';
import React, { FC, useEffect } from 'react';
import { AudioState } from 'context/AudioContext';
import AudioPlayerVolume from './AudioPlayerVolume';
import AudioPlayerPlayControls from './AudioPlayerPlayControls';
import AudioPlayerProgressBar from './AudioPlayerProgressBar';
import AudioPlayerTrackInfo from './AudioPlayerTrackInfo';
import IconRockOn from 'components/Icon/IconRockOn';
import IconCalendar from 'components/Icon/IconCalendar';
import IconMusicNote from 'components/Icon/IconMusicNote';
import Link from 'next/link';
import AddFavoriteWrapper from 'components/Data/AddFavoriteWrapper';

const AudioPlayer: FC<AudioState> = (props) => {
  useEffect(() => {
    props.loadPlayer && props.loadPlayer();
  }, [props?.playerElementRef?.current]);

  return (
    <aside
      id="player"
      className="audio-player player js-player"
      ref={props.playerElementRef}
      data-src={props.source}
      aria-label="Audio Player"
      role="region"
    >
      <>
        <audio
          id="main-audio"
          preload="none"
          src={props.source}
          ref={props.audioElementRef}
        />
        <div className="wrapper playerWrapper">
          <div className="playerInnerWrapper">
            <div className="playerMain">
              <AudioPlayerPlayControls
                isInfinite={props.duration === Infinity}
                isPlaying={props.isAudioPlaying}
              />

              <AudioPlayerVolume />

              <AudioPlayerTrackInfo
                title={props.title}
                subTitle={props.subtitle}
              />

              <AudioPlayerProgressBar
                isInfinite={props.duration === Infinity}
              />
            </div>
            <div className="additionalButtons">
              {props.song ? (
                <AddFavoriteWrapper
                  title={props.song.title}
                  contentArea={
                    process.env.NEXT_PUBLIC_CONTENT_AREA_SLUG ?? 'the-current'
                  }
                  resourceType="song"
                  slug=""
                  id={props.song.song_id?.toString() ?? ''}
                  album={props.song.album ?? undefined}
                  artist={props.song.artist ?? undefined}
                  duration={props.song.duration ?? undefined}
                  art_url={props.song.art_url ?? undefined}
                />
              ) : (
                <button disabled={true} className="disabled">
                  <IconRockOn width="1.2rem" height="1.3rem" />
                  <span>Save</span>
                </button>
              )}
              <Link
                className="audio-btn-link"
                href={`/playlist/the-current`}
                prefetch={false}
                passHref
              >
                <IconMusicNote />
                <span>Playlist</span>
              </Link>
              <Link
                className="audio-btn-link"
                href={`/schedule/the-current`}
                prefetch={false}
                passHref
              >
                <IconCalendar />
                <span>Schedule</span>
              </Link>
            </div>
            <Link
              className="btn largeScreenOnly"
              href="https://support.mpr.org/current-web"
              prefetch={false}
            >
              Donate
            </Link>
          </div>
        </div>
      </>
    </aside>
  );
};

export default AudioPlayer;
