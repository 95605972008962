import { FC } from 'react';

interface Props {
  height?: string;
  width?: string;
}

const IconMenuWatch: FC<Props> = ({ height = '18', width = '18' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 11.25V6.75C16.5 3 15 1.5 11.25 1.5H6.75C3 1.5 1.5 3 1.5 6.75V11.25C1.5 15 3 16.5 6.75 16.5H11.25C15 16.5 16.5 15 16.5 11.25Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.88965 5.33252H16.1096"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.38965 1.58252V5.22752"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6104 1.58252V4.89002"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.3125 10.8376V9.93759C7.3125 8.78259 8.13 8.31009 9.1275 8.88759L9.9075 9.33759L10.6875 9.78759C11.685 10.3651 11.685 11.3101 10.6875 11.8876L9.9075 12.3376L9.1275 12.7876C8.13 13.3651 7.3125 12.8926 7.3125 11.7376V10.8376V10.8376Z"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconMenuWatch;
